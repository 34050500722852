@import "~modern-normalize/modern-normalize.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857142857143;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7em;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background-color: unset;
}

#root {
    height: 100vh;
    width: 100%;
}